<template>
    <div id="contents" class="contents main_video page">
        <div class="content_head">
            <!-- s: 페이지 타이틀 -->
            <div class="page_ttl_wrap has_btn">
                <h2 class="page_ttl">메인 영상 관리</h2>
                <div class="btn_group">
                    <span>최근 발행일시 {{recentPublishTime ? moment(recentPublishTime).format('YYYY.MM.DD HH:mm:ss') : '없음'}}</span>
                    <!-- Dev: 변경사항이 없을시 비활성화 -->
                    <button type="button" class="btn strong2 w_m h_l" @click="publish">발행하기</button>
                </div>
            </div>
        </div>
        <!-- e: 페이지 타이틀 -->
        <!-- s: content_body -->
        <div class="content_body">
            <!-- s: item_list_sec-->
            <div class="item_list_sec">
                <!-- Dev: 영상 추가 wrap -->
                <div class="item_list_wrap none" :class="{is_active : activeIndex === -1}"
                     @click="clickAddBtn">
                    <div class="item_list_head">
                        <div class="item_thumb">
                            <button>추가버튼</button>
                        </div>
                    </div>
                </div>
                <!-- Dev : 등록된 영상  -->
                <div class="registration_item_wrap">
                    <Container class="registration_item_area"
                               orientation="horizontal"
                               tag="div"
                               :drop-placeholder="dropPlaceholderOptions"
                               @drop="onDrop">
                        <Draggable class="item_list_wrap" v-for="(item, i) in mainVideos" :key="'item' + i"
                                   :class="{is_active : i === activeIndex}" @click="clickMainVideo(i, item)">
                            <div class="item_list_head">
                                <div class="item_thumb">
                                    <img src="../../../assets/img/common/play_btn.png" alt="">
                                </div>
                                <div class="delete_btn_area">
                                    <button class="delete_btn" @click="removeList(item)">삭제버튼</button>
                                </div>
                            </div>
                            <div class="item_list_body">
                                {{item.file && item.file.name ? item.file.name : fileNameFromUrl(item.file)}}
                            </div>
                        </Draggable>
                    </Container>
                </div>
            </div>
            <!-- e: item_list_sec-->

            <!-- s: preview_sec -->
            <div class="preview_sec">
                <div class="preview_wrap">
                    <div class="upload_guide" role="button">
                      <div class="tooltip_area">
                        <img src="../../../assets/img/common/tooltip.png" alt="">
                        <p>파일 업로드 가이드</p>
                      </div>
                        <div class="layer_info">
                            <div class="info_area" v-for="(item, i) in tooltip" :key="'item' + i">
                                <div class="ttl">{{item.ttl}}</div>
                                <div class="cnt">
                                    <p>{{item.file}}</p>
                                    <p>{{item.memory}}</p>
                                    <p>{{item.display}}</p>
                                </div>
                            </div>
                          <p class="info_txt">※ 파일명은 영어로 등록해주세요.</p>
                        </div>
                    </div>
                    <div class="preview_area">
                        <!-- Dev s: 등록된 파일 있을 경우 -->
                        <template v-if="clickVideo">
                            <div class="upload_video">
                                <video :ref="`videoRef`" class="video_box" style="background-color: #333">
                                    <source :src="getFile(clickVideo.file)">
                                </video>
                                <button class="video_play_btn play" @click="playVideo('play')" v-if="!playStatus">
                                    <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                </button>
                                <button class="video_play_btn pause" @click="playVideo('pause')" v-else>
                                    <img src="../../../assets/img/common/video_pause_btn.png" alt="">
                                </button>
                                <div class="hover_btn_area">
                                    <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                    <button class="modify_btn">
                                        <img src="../../../assets/img/common/video_modify.png" alt="">
                                        <input type="file" accept=".mp4" @change="onFileChange($event)">
                                    </button>
                                    <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                    <button class="delete_btn" @click="removeList(clickVideo)">삭제</button>
                                </div>
                            </div>
                        </template>
                        <!-- Dev e: 등록된 파일 있을 경우 -->
                        <!-- Dev s: 등록된 파일 없을 경우 -->
                        <template v-else>
                            <div class="upload_btn" role="button">
                                <div class="txt_area">
                                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                </div>
                                <div class="btn_area">
                                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                </div>
                                <div class="info_area">
                                    <p>mp4, 1920*1080</p>
                                </div>
                            </div>
                        </template>
                        <!-- Dev e: 등록된 파일 없을 경우 -->
                    </div>
                </div>
            </div>
            <!-- e: preview_sec -->
        </div>
        <!-- e: content_body -->

        <!-- s: content_foot -->
        <div class="content_foot">
            <div class="btn_group center">
                <!-- Dev : 첨부파일에 대한 부분만 리셋        -->
<!--                <button @click="clickAddBtn" class="btn basic w_m h_l">취소</button>-->
                <!-- Dev : 클릭 시 메인 영상 등록: 파일 첨부 반영  -->
                <button v-if="activeIndex === -1" class="btn strong w_m h_l" @click="uploadVideo">등록</button>
                <button v-else class="btn strong w_m h_l" @click="editVideo">저장</button>
            </div>
        </div>
        <!-- e : content_foot  -->
    </div>
    <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false"
            @yesDialog="deleteVideo()">
        <template #body>
            <h3>삭제 확인</h3>
            <p>선택한 영상을 삭제하시겠습니까?<br>
                변경된 내용은 “발행하기”를 진행해야 체험존에 반영됩니다</p>
        </template>
    </Dialog>

    <!-- Dev :  페이지 나가기, 새로고침, 브라우저 닫기 시 호출 -->
    <Dialog :isOpen="pageOutDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="pageOutDialogState = false"
            @yesDialog="pageOut()">
        <template #body>
            <h3>페이지에서 나가시겠습니까?</h3>
            <p>변경된 내용은 “발행하기”를 진행해야 체험존에 반영됩니다.</p>
        </template>
    </Dialog>
</template>

<script>
import moment from "moment";
import {Container, Draggable} from "vue3-smooth-dnd";
import Dialog from "@/components/dialog";
import MainVideo from "@/plugins/firebase/model/mainVideo";
import {fileNameFromUrl} from '@/plugins/network';
import getBlobDuration from "get-blob-duration";
import VMD from "@/plugins/firebase/model/vmd";
import {applyDrag} from "@/plugins/dnd-helpers";

export default {
    name: "new-index",
    layout: {
        name: 'new-admin',
    },
    components: {
        Container: Container,
        Draggable: Draggable,
        Dialog,
    },
    data() {
        return {
            moment: moment,
            dropPlaceholderOptions: {
                className: 'drop-preview',
                animationDuration: '150',
                showOnTop: true
            },
            activeIndex: -1,
            playStatus: false,
            deleteDialogState: false,
            pageOutDialogState: false,
            tooltip: [
                {
                    ttl: '메인 영상',
                    file: 'MP4',
                    memory: '용량 130MB 이하',
                    display: '1920*1080',
                },
            ],
            recentPublishTime: '',
            mainVideos: [],
            originalVideos: {},
            clickVideo: null,
            clickToDeleteVideo: null,
            main_video_publish_key: 'publish/mainVideo',
            main_video_root_key: 'mainVideo',
            canLeaveSite: true,
            isUploading: false,
        }
    },
    methods: {
        fileNameFromUrl: fileNameFromUrl,
        async publish() {
            const time = this.timeToString(moment());
            await this.$firebase.database().ref(this.main_video_publish_key).child('lastPublishDateTime').set(time);
            this.toastSuccess('발행 완료.');
        },
        onDrop(dropResult) {
            if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
                this.mainVideos[dropResult.removedIndex].order = dropResult.addedIndex + 1;
                this.mainVideos[dropResult.addedIndex].order = dropResult.removedIndex + 1;

                /*const removed = this.mainVideos[dropResult.removedIndex];
                const added = this.mainVideos[dropResult.addedIndex];
                this.$firebase.database().ref(this.main_video_root_key).child(removed.hash).set(removed);
                this.$firebase.database().ref(this.main_video_root_key).child(added.hash).set(added);*/
            }
            this.mainVideos = applyDrag(this.mainVideos, dropResult);
            this.mainVideos.forEach((item, index) => {
                item.order = index + 1;
            });
            this.mainVideos = this.mainVideos.sort((a, b) => {
                return a.order - b.order;
            });
            this.mainVideos.forEach((item) => {
                this.$firebase.database().ref(this.main_video_root_key).child(item.hash).set(item);
            });
            this.clickMainVideo(dropResult.addedIndex, this.mainVideos[dropResult.addedIndex]);
            /*this.activeIndex = dropResult.addedIndex;
            this.clickedVmd = this.mainVideos[this.activeIndex];*/
            this.canLeaveSite = false;
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            let file = files[0];

            const isVideo = this.isVideo(file);
            const maxVideoSize = 130 * 1024 * 1024;
            if (isVideo) {
                if (maxVideoSize <= file.size) {
                    this.toastDanger('동영상 용량은 130MB 이하로 등록해주세요.');
                    return;
                }
            } else {
                this.toastDanger('MP4 동영상 파일만 가능합니다.');
                return;
            }

            if (this.activeIndex === -1) {
                this.clickVideo = new MainVideo();
            }
            this.clickVideo.file = file;
            const video = this.$refs.videoRef;
            if (video) {
                this.playStatus = false;
                const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
                if (isVideoPlaying) {
                    video.pause();
                }
                video.load();
            }
            this.canLeaveSite = false;
        },
        async uploadVideo() {
            if(this.isUploading){
                return;
            }

            this.isUploading = true;
            this.loadingOverlay(async (loader) => {
                let instance = this.clickVideo;
                instance.order = 1;
                for await (const item of this.mainVideos) {
                    const index = this.mainVideos.indexOf(item);
                    item.order = index + 2;
                    await this.$firebase.database().ref(this.main_video_root_key).child(item.hash).set(item);
                }
                instance.creationDateTime = this.timeToString(moment());
                if (instance && instance.file instanceof File) {
                    const hash = this.$firebase.database().ref(this.main_video_root_key).push(instance).key;
                    instance.playTime = await this.getFileDuration(instance.file);
                    let downloadUrl = await this.upload(hash, instance.file, 0, 1);
                    instance.file = downloadUrl;
                    instance.lastModifiedDateTime = this.timeToString(moment());
                    await this.$firebase.database().ref(this.main_video_root_key).child(hash).set(instance).then(() => {
                        this.toastSuccess('메인 영상이 등록되었습니다.');
                        if (this.activeIndex === -1) {
                            this.activeIndex = this.mainVideos.length - 1;
                            this.clickedVmd = this.mainVideos[this.activeIndex];
                        }
                        this.reloadData().then(() => {
                            if(data){
                                const videos = data.val();
                                if(videos){
                                    for(const [hash, video] of Object.entries(videos)){
                                        this.originalVideos[hash] = JSON.parse(JSON.stringify(video));
                                    }
                                }
                            }
                            this.clickMainVideo(0, this.mainVideos[0]);
                        });
                    });
                }
                this.canLeaveSite = false;

                this.isUploading = false;
                loader.hide();
            });
        },
        async editVideo() {
            let instance = this.clickVideo;
            if (instance && instance.file instanceof File) {
                instance.playTime = await this.getFileDuration(instance.file);
                let downloadUrl = await this.upload(instance.hash, instance.file, 0, 1);
                instance.file = downloadUrl;
                instance.lastModifiedDateTime = this.timeToString(moment());
                await this.$firebase.database().ref(this.main_video_root_key).child(instance.hash).set(instance).then(() => {
                    this.toastSuccess('메인 영상이 수정되었습니다.');
                    this.reloadData().then((data) => {
                        if(data){
                            const videos = data.val();
                            if(videos){
                                for(const [hash, video] of Object.entries(videos)){
                                    this.originalVideos[hash] = JSON.parse(JSON.stringify(video));
                                }
                            }
                        }
                    });
                    //this.redirect('/channel');
                });
            }
            this.canLeaveSite = false;
        },
        playVideo(status) {
            const video = this.$refs.videoRef;
            if (status === 'play') {
                video.play();
                this.playStatus = true;
            } else {
                video.pause();
                this.playStatus = false;
            }
        },
        async deleteVideo() {
            this.deleteDialogState = false;
          // 2022-01-25 파일 삭제 임시 주석
            //await this.deleteFolderContents(this.clickToDeleteVideo.hash);
            this.$firebase.database().ref(this.main_video_root_key).child(this.clickToDeleteVideo.hash).remove().then(async () => {
                this.clickToDeleteVideo = null;
                this.toastSuccess('영상이 삭제 되었습니다.');
                this.reloadData().then(() => {
                    if(this.mainVideos && this.mainVideos.length > 0){
                        this.clickMainVideo(0, this.mainVideos[0]);
                    }else{
                        this.clickAddBtn();
                    }
                });
            });
            this.canLeaveSite = false;
            // this.redirect('/vmd');
        },
        removeList(item) {
            if(item && item.file && item.file instanceof File){
                this.clickVideo = null;
            }else{
                this.clickToDeleteVideo = item;
                this.deleteDialogState = true;
            }

            this.canLeaveSite = false;
        },
        deleteFolderContents(path) {
            const ref = this.$firebase.storage().ref(path);
            return ref.listAll()
                    .then(dir => {
                        dir.items.forEach(fileRef => {
                            this.deleteFile(ref.fullPath, fileRef.name);
                        });
                        dir.prefixes.forEach(folderRef => {
                            this.deleteFolderContents(folderRef.fullPath);
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    });
        },
        deleteFile(pathToFile, fileName) {
            const ref = this.$firebase.storage().ref(pathToFile);
            const childRef = ref.child(fileName);
            childRef.delete();
            this.canLeaveSite = false;
        },
        pageOut() {
            this.pageOutDialogState = false;
        },

        clickAddBtn() {
            this.activeIndex = -1;
            this.clickVideo = null;
        },
        clickMainVideo(index, video) {
            if(this.clickVideo){
                const prevHash = this.clickVideo.hasOwnProperty('hash') ? this.clickVideo.hash : null;
                if(prevHash && this.originalVideos.hasOwnProperty(prevHash)){
                    const original = this.originalVideos[prevHash];
                    const find = this.mainVideos.findIndex((item) => item.hash === prevHash);
                    if(find > -1){
                        this.mainVideos[find] = JSON.parse(JSON.stringify(original));
                    }
                }
            }

            this.activeIndex = index;
            this.clickVideo = video;

            const videoRef = this.$refs.videoRef;
            if (videoRef) {
                this.playStatus = false;
                const isVideoPlaying = videoRef => !!(videoRef.currentTime > 0 && !videoRef.paused && !videoRef.ended && videoRef.readyState > 2);
                if (isVideoPlaying) {
                    videoRef.pause();
                }
                videoRef.load();
            }
        },
        async upload(hash, image, current, total) {
            let storageRef = this.$firebase.storage().ref();
            let imageRef = storageRef.child(hash).child(image.name);
            let uploadTask = imageRef.put(image);
            return new Promise(((resolve, reject) => {
                uploadTask.on('state_changed', (snapshot) => {
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const gage = (Math.floor(100 / total) * current) + (Math.floor(progress) / total);
                    this.$Progress.set(gage);
                    //console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case this.$firebase.storage.TaskState.PAUSED: // or 'paused'
                            //console.log('Upload is paused');
                            break;
                        case this.$firebase.storage.TaskState.RUNNING: // or 'running'
                            //console.log('Upload is running');
                            break;
                    }
                }, (error) => {
                    console.error('[ERROR] state_changed', error);
                    reject(error);
                }, () => {
                    // 완료 다운로드 예시
                    resolve(uploadTask.snapshot.ref.getDownloadURL());
                });
            }));
        },
        async getFileDuration(file) {
            const isVideo = this.isVideo(file);
            const isImage = this.isImage(file);
            if (isVideo) {
                const blob = URL.createObjectURL(file);
                return await getBlobDuration(blob);
            } else if (isImage) {
                return new VMD().imagePlayTime;
            } else {
                if (file) {
                    //console.log(file);
                    const url = file.toString().toLowerCase();
                    if (url.indexOf('.mp4')) {
                        const blob = URL.createObjectURL(file);
                        return await getBlobDuration(blob);
                    } else {
                        return new VMD().imagePlayTime;
                    }
                }
            }
            return 0;
        },
        reloadData() {
            return this.$firebase.database().ref(this.main_video_root_key).once('value', (sn) => {
                //this.mainVideos = sn.val() || {};
                const videos = sn.val() || {};
                let mainVideos = [];
                for (const [hash, obj] of Object.entries(videos)) {
                    obj.hash = hash;
                    mainVideos.push(obj);
                }
                this.mainVideos = mainVideos.sort((a, b) => {
                    return a.order - b.order
                });
            })
        },
    },
    created() {
        this.$firebase.database().ref(this.main_video_publish_key).on('value', (sn) => {
            const publish = sn.val() || {};
            if (publish) {
                this.recentPublishTime = publish.lastPublishDateTime;
            }
        });

        this.reloadData().then((data) => {
            if(data){
                const videos = data.val();
                if(videos){
                    for(const [hash, video] of Object.entries(videos)){
                        this.originalVideos[hash] = JSON.parse(JSON.stringify(video));
                    }
                }
            }
            if(this.mainVideos && this.mainVideos.length > 0){
                this.clickMainVideo(0, this.mainVideos[0]);
            }else{
                this.clickAddBtn();
            }
        });
    },
    mounted() {
        window.addEventListener('beforeunload', this.unLoadEvent);
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.unLoadEvent);
    },
    beforeRouteLeave(to, from, next) {
        if (this.canLeaveSite) next();
        else if (confirm('이 사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.')) {
            next();
        }
    }
}
</script>

<style scoped>

</style>
