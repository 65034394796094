import {TEMPLATE} from "@/plugins/firebase/type";

export default class MainVideo {
    constructor(options = {}) {
        Object.assign(this, {
            order: 0,
            creationDateTime: '',
            lastModifiedDateTime: '',
            playTime: 0.0,
            file: '',
        }, options);
        Object.assign(this, options);
    }
}